import React from 'react';
import './Footer.css';  // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <span>© {new Date().getFullYear()} Gem Guardians</span>
    </footer>
  );
}

export default Footer;
