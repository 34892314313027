import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './PuzzleSolving.css';

const revealVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2 } }
};

const PuzzleSolving = ({ onEarnResources }) => {
  const [solved, setSolved] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [correctTiles, setCorrectTiles] = useState(Array(3).fill(false));

  const questions = [
    {
      question: 'What is a blockchain?',
      options: [
        'A type of video game',
        'A digital ledger of transactions',
        'A physical chain made of blocks',
        'An online shopping platform',
      ],
      answer: 1,
      info: 'A blockchain is a digital ledger of transactions that is duplicated and distributed across the entire network of computer systems on the blockchain.',
    },
    {
      question: 'What is a private key?',
      options: [
        'A key to open a private room',
        'A secret code that allows you to access your digital assets',
        'A password for your email account',
        'A key to unlock a car',
      ],
      answer: 1,
      info: 'A private key is a secret code that allows you to access and manage your digital assets stored on the blockchain.',
    },
    {
      question: 'What does decentralization mean?',
      options: [
        'A central authority controls everything',
        'Power and control are spread out across many people or computers',
        'A single computer handles all transactions',
        'A type of centralized government',
      ],
      answer: 1,
      info: 'Decentralization means that power and control are spread out across many people or computers, making the system more secure and transparent.',
    },
  ];

  const handleOptionClick = (index) => {
    if (index === questions[currentQuestion].answer) {
      setScore(prevScore => prevScore + 1);
      onEarnResources(20); // Earn 20 resources for a correct answer
      const newCorrectTiles = [...correctTiles];
      newCorrectTiles[currentQuestion] = true;
      setCorrectTiles(newCorrectTiles);
    }
    setSolved(true);
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setSolved(false);
      setCurrentQuestion(currentQuestion + 1);
    } else {
      alert(`You have completed the quiz! Your score is ${score}/${questions.length}`);
      setCurrentQuestion(0);
      setScore(0);
      setCorrectTiles(Array(3).fill(false));
    }
  };

  return (
    <div className="puzzle-container">
      <motion.h2
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Puzzle Solving
      </motion.h2>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Solve the puzzle to learn about blockchain transactions and digital assets.
      </motion.p>
      <div>
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          {questions[currentQuestion].question}
        </motion.h3>
        <motion.ul
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          {questions[currentQuestion].options.map((option, index) => (
            <motion.li key={index} variants={revealVariants}>
              <button onClick={() => handleOptionClick(index)} disabled={solved}>
                {option}
              </button>
            </motion.li>
          ))}
        </motion.ul>
      </div>
      {solved && (
        <motion.div
          className="info"
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          <p>{questions[currentQuestion].info}</p>
          <button onClick={handleNextQuestion}>
            {currentQuestion < questions.length - 1 ? 'Next Question' : 'View Results'}
          </button>
        </motion.div>
      )}
      <div className="tile-grid">
        {correctTiles.map((isCorrect, index) => (
          <motion.div
            key={index}
            className={`tile ${isCorrect ? 'correct' : ''}`}
            initial="hidden"
            animate="visible"
            variants={revealVariants}
          ></motion.div>
        ))}
      </div>
      {score === questions.length && (
        <motion.pre
          className="dog-ascii reveal"
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          {`
           /\\_/\\  
          ( o.o ) 
           > ^ <
          `}
        </motion.pre>
      )}
    </div>
  );
};

export default PuzzleSolving;
