import React from 'react';
import { motion } from 'framer-motion';
import './ResourceManager.css';  // Import the CSS file

const revealVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2 } }
};

const ResourceManager = ({ resources, tasksCompleted }) => {
  return (
    <div className="resource-manager">
      <motion.h2
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Resource Manager
      </motion.h2>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Manage your resources to grow your gem collection.
      </motion.p>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Current Resources: {resources}
      </motion.p>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Tasks Completed: {tasksCompleted}
      </motion.p>
      <motion.div
        className="resource-visualization"
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        <h3>Your Gems</h3>
        {resources === 0 ? (
          <motion.p
            initial="hidden"
            animate="visible"
            variants={revealVariants}
          >
            You haven't collected any gems yet. Complete tasks to earn gems!
          </motion.p>
        ) : (
          <motion.div
            className="gems"
            initial="hidden"
            animate="visible"
            variants={revealVariants}
          >
            {Array.from({ length: Math.floor(resources / 20) }).map((_, index) => (
              <motion.div
                key={index}
                className="gem"
                initial="hidden"
                animate="visible"
                variants={revealVariants}
              >
                💎
              </motion.div>
            ))}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}

export default ResourceManager;
