import React, { useState } from 'react';
import { motion } from 'framer-motion';
// import './GuardianSkills.css';

const revealVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2 } }
};

const GuardianSkills = () => {
  const [skills, setSkills] = useState([]);
  const [skillRank, setSkillRank] = useState('Novice');
  const [materialReview, setMaterialReview] = useState([]);

  const handleLearn = () => {
    const newSkill = `Skill ${skills.length + 1}`;
    const newMaterial = `Review Material for ${newSkill}`;
    setSkills([...skills, newSkill]);
    setMaterialReview([...materialReview, newMaterial]);

    // Update skill rank based on the number of skills
    const updatedSkills = [...skills, newSkill];
    if (updatedSkills.length >= 10) {
      setSkillRank('Master');
    } else if (updatedSkills.length >= 5) {
      setSkillRank('Advanced');
    } else {
      setSkillRank('Novice');
    }
  }

  return (
    <div className="guardian-skills">
      <motion.h2
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Guardian Skills
      </motion.h2>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Earn new skills and powers by learning about financial principles.
      </motion.p>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Current Skill Rank: {skillRank}
      </motion.p>
      <motion.button
        onClick={handleLearn}
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Learn New Skill
      </motion.button>
      <motion.ul
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        {skills.map((skill, index) => (
          <motion.li key={index} variants={revealVariants}>{skill}</motion.li>
        ))}
      </motion.ul>
      <div className="material-review">
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          Review Material
        </motion.h3>
        <motion.ul
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          {materialReview.map((material, index) => (
            <motion.li key={index} variants={revealVariants}>{material}</motion.li>
          ))}
        </motion.ul>
      </div>
    </div>
  );
}

export default GuardianSkills;
