import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Home.css';  // Import the CSS file

const revealVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2 } }
};

const Home = () => {
  return (
    <div className="home">
      <motion.h1
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Welcome to Gem Guardians
      </motion.h1>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={revealVariants}
      >
        Embark on an adventure to learn about self-custody and financial literacy.
      </motion.p>
      <div className="bento-grid">
        <motion.div
          className="bento-item puzzle-solving"
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          <Link to="/puzzle-solving">
            <h2>Puzzle Solving</h2>
            <p>Solve puzzles to learn about blockchain</p>
          </Link>
        </motion.div>
        <motion.div
          className="bento-item resource-management"
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          <Link to="/resource-manager">
            <h2>Resource Manager</h2>
            <p>Manage resources and grow your gem collection</p>
          </Link>
        </motion.div>
        <motion.div
          className="bento-item guardian-skills"
          initial="hidden"
          animate="visible"
          variants={revealVariants}
        >
          <Link to="/guardian-skills">
            <h2>Guardian Skills</h2>
            <p>View your skill rank and review materials</p>
          </Link>
        </motion.div>
      </div>
    </div>
  );
}

export default Home;
