import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import PuzzleSolving from './components/PuzzleSolving';
import ResourceManager from './components/ResourceManager';
import GuardianSkills from './components/GuardianSkills';
import Footer from './components/Footer';  // Import the Footer component
import './App.css';  // Import the CSS file

const App = () => {
  const [resources, setResources] = useState(100);
  const [tasksCompleted, setTasksCompleted] = useState(0);

  const handleEarnResources = (amount) => {
    setResources(resources + amount);
    setTasksCompleted(tasksCompleted + 1);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/puzzle-solving">Puzzle Solving</Link></li>
            <li><Link to="/resource-manager">Resource Manager</Link></li>
            <li><Link to="/guardian-skills">Guardian Skills</Link></li>
          </ul>
        </nav>
        <div className="container flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/puzzle-solving" element={<PuzzleSolving onEarnResources={handleEarnResources} />} />
            <Route path="/resource-manager" element={<ResourceManager resources={resources} tasksCompleted={tasksCompleted} />} />
            <Route path="/guardian-skills" element={<GuardianSkills />} />
          </Routes>
        </div>
        <Footer /> 
      </div>
    </Router>
  );
}

export default App;
